import { generateAssetsContextValue } from '@gaming1/g1-assets-management/web';
import { MultifactorAuthenticationAssetsContext } from '@gaming1/g1-multifactor-authentication/web';

import googleAuthenticator from './mfaConfiguration/googleAuthenticator.svg';
import mobileAppStoreBadge from './mfaConfiguration/mobileAppStoreBadge.svg';
import mobileAppStoreGoogleBadge from './mfaConfiguration/mobileAppStoreGoogleBadge.svg';

export const multifactorAuthenticationAssets = generateAssetsContextValue(
  MultifactorAuthenticationAssetsContext,
)({
  /* MFA configuration */
  googleAuthenticator,
  mobileAppStoreBadge,
  mobileAppStoreGoogleBadge,
});
