import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { BettingSearchResult } from '@gaming1/g1-betting-web';
import { currentSectionSelector } from '@gaming1/g1-core';
import { GamingSearchResult } from '@gaming1/g1-gaming-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { SearchDrawer } from '@gaming1/g1-search/web';
import { RemoteData, RequestState } from '@gaming1/g1-utils';

export const AppSearchDrawer: FC = () => {
  const { t } = useTranslation('core');

  const [gamingFailureTerm, setGamingFailureTerm] = useState<string | null>(
    null,
  );

  const [bettingFailureTerm, setBettingFailureTerm] = useState<string | null>(
    null,
  );

  const [gamingRequestFailureState, setGamingRequestFailureState] =
    useState<RequestState | null>(null);

  const [bettingRequestFailureState, setBettingRequestFailureState] =
    useState<RequestState | null>(null);

  const failureTerm =
    bettingRequestFailureState?.status === RemoteData.Error ||
    gamingRequestFailureState?.status === RemoteData.Error ||
    (gamingFailureTerm?.length && bettingFailureTerm?.length)
      ? gamingFailureTerm || bettingFailureTerm
      : null;

  /**
   * If the user is searching when being in the "gaming" section,
   * it'll be the gaming search results that will be displayed in priority,
   * then betting.
   *
   * If the user is searching in the homepage (as an example), the fallback order
   * is used.
   *
   * The fallback order is : gaming > betting
   */
  const currentSection = useSelector(currentSectionSelector);

  const createResultDisplay = () => {
    const casinoCategory = (
      <GamingSearchResult
        key="gamingResult"
        onTermFailure={setGamingFailureTerm}
        onRequestFailure={setGamingRequestFailureState}
      />
    );
    const bettingCategory = (
      <BettingSearchResult
        key="bettingResult"
        onTermFailure={setBettingFailureTerm}
        onRequestFailure={setBettingRequestFailureState}
      />
    );

    switch (currentSection) {
      case 'betting':
        return (
          <>
            {bettingCategory}
            {casinoCategory}
          </>
        );
      case 'default':
      case 'gaming':
      default:
        return (
          <>
            {casinoCategory}
            {bettingCategory}
          </>
        );
    }
  };

  return (
    <SearchDrawer
      noResultTerm={failureTerm}
      inputPlaceHolder={t('search.placeholder')}
      minCharacters={3}
    >
      {createResultDisplay()}
    </SearchDrawer>
  );
};
