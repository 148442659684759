import { generateAssetsContextValue } from '@gaming1/g1-assets-management/web';
import { GamingAssetsContext } from '@gaming1/g1-gaming-web';

import jackpotGameTag from './gamecard/jackpot.svg';
import newGameTag from './gamecard/new.svg';
import topGameTag from './gamecard/top.svg';

// TODO: This file duplicated the game catalog assets for now as the migration
// is ongoing. When everything is done, delete this along with the g1-gaming-web
// context.

export const gamingAssets = generateAssetsContextValue(GamingAssetsContext)({
  /* Game tags */
  jackpotGameTag,
  newGameTag,
  topGameTag,
});
