import { generateAssetsContextValue } from '@gaming1/g1-assets-management/web';
import { BettingAssetsContext } from '@gaming1/g1-betting-utils';

import emptyStateCombination from './bettingSlip/empty-state-combination.svg';
import emptyStateSingle from './bettingSlip/empty-state-single.svg';
import emptyStateSystem from './bettingSlip/empty-state-system.svg';
import boostusBannerLarge from './boostus/booster_desktop.png';
import boostusBannerSmall from './boostus/booster_mobile.png';
import dailyOffersBannerLarge from './dailyOffers/bestodds_desktop.png';
import dailyOffersBannerSmall from './dailyOffers/bestodds_mobile.png';
import promotionsBannerLarge from './promotions/offers_desktop.png';
import promotionsBannerSmall from './promotions/offers_mobile.png';
import americanFootballScoreBackground from './score/american-football-background.jpg';
import baseballScoreBackground from './score/baseball-background.jpg';
import basketballScoreBackground from './score/basketball-background.jpg';
import bowlsScoreBackground from './score/bowls-background.jpg';
import boxingScoreBackground from './score/boxing-background.jpg';
import cricketScoreBackground from './score/cricket-background.jpg';
import cyclingScoreBackground from './score/cycling-background.jpg';
import dartsScoreBackground from './score/darts-background.jpg';
import defaultScoreBackground from './score/default-background.jpg';
import footballScoreBackground from './score/football-background.jpg';
import golfScoreBackground from './score/golf-background.jpg';
import handballScoreBackground from './score/handball-background.jpg';
import iceHockeyScoreBackground from './score/ice-hockey-background.jpg';
import martialArtsScoreBackground from './score/martial-arts-background.jpg';
import mixedMartialArtsScoreBackground from './score/mixed-martial-arts-background.jpg';
import motorCycleRacingScoreBackground from './score/motor-cycle-racing-background.jpg';
import motorSportScoreBackground from './score/motor-sport-background.jpg';
import rugbyScoreBackground from './score/rugby-background.jpg';
import snookerScoreBackground from './score/snooker-background.jpg';
import stockCarRacingScoreBackground from './score/stock-car-racing-background.jpg';
import tableTennisScoreBackground from './score/table-tennis-background.jpg';
import tennisScoreBackground from './score/tennis-background.jpg';
import volleyballScoreBackground from './score/volleyball-background.jpg';
import defaultSliderLarge from './sliders/default-slider-large.jpg';
import defaultSliderSmall from './sliders/default-slider-small.jpg';
import superOddsBannerLarge from './superOdds/superodds_desktop.png';
import superOddsBannerSmall from './superOdds/superodds_mobile.png';
import tournamentorBannerDesktop from './tournamentor/tournament_desktop.png';
import tournamentorBannerMobile from './tournamentor/tournament_mobile.png';

export const bettingAssets = generateAssetsContextValue(BettingAssetsContext)({
  /* Betting slip empty states */
  emptyStateCombination,
  emptyStateSingle,
  emptyStateSystem,
  /* Banners */
  boostusBannerLarge,
  boostusBannerSmall,
  dailyOffersBannerLarge,
  dailyOffersBannerSmall,
  defaultSliderLarge,
  defaultSliderSmall,
  promotionsBannerLarge,
  promotionsBannerSmall,
  superOddsBannerLarge,
  superOddsBannerSmall,
  tournamentorBannerDesktop,
  tournamentorBannerMobile,
  /* Score backgrounds */
  americanFootballScoreBackground,
  baseballScoreBackground,
  basketballScoreBackground,
  bowlsScoreBackground,
  boxingScoreBackground,
  cricketScoreBackground,
  cyclingScoreBackground,
  dartsScoreBackground,
  defaultScoreBackground,
  footballScoreBackground,
  golfScoreBackground,
  handballScoreBackground,
  iceHockeyScoreBackground,
  martialArtsScoreBackground,
  mixedMartialArtsScoreBackground,
  motorCycleRacingScoreBackground,
  motorSportScoreBackground,
  rugbyScoreBackground,
  snookerScoreBackground,
  stockCarRacingScoreBackground,
  tableTennisScoreBackground,
  tennisScoreBackground,
  volleyballScoreBackground,
});
