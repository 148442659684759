import React, { ContextType, FC, ReactNode } from 'react';

import { ImageContext } from '@gaming1/g1-assets-management/web';

import logoLight from '../assets/core/logos/logo_light.svg';
import tournamentLost from '../assets/custom/tournaments/lost.png';
import tournamentWon from '../assets/custom/tournaments/win.png';

const imagesContextValue: ContextType<typeof ImageContext> = {
  // Fullscreen
  'header-logo': logoLight,
  // Tournaments
  'info-panel-lost': tournamentLost,
  'info-panel-won': tournamentWon,
};

export const ImagesProvider: FC<{ children?: ReactNode }> = ({ children }) => (
  <ImageContext.Provider value={imagesContextValue}>
    {children}
  </ImageContext.Provider>
);
