import {
  CoreAssetsContext,
  generateAssetsContextValue,
} from '@gaming1/g1-assets-management/web';

import genericError from './errors/generic_error.svg';
import networkError from './errors/network_error.svg';
import backToTheTopArrow from './footer/backtop.svg';
import androidApkEn from './geolocation/android-apk-en.svg';
import appStoreComingSoonEn from './geolocation/app-store-coming-soon-en.svg';
import appStoreEn from './geolocation/app-store-en.svg';
import googlePlayComingSoonEn from './geolocation/google-play-coming-soon-en.svg';
import googlePlayEn from './geolocation/google-play-en.svg';
import laptop from './geolocation/laptop.png';
import mobile from './geolocation/mobile.png';
import notFound from './illustrations/error-404.png';
import failure from './illustrations/failure.png';
import noResults from './illustrations/no-result.png';
import questioning from './illustrations/questioning.png';
import repair from './illustrations/repair.png';
import successCashier from './illustrations/success-cashier.png';
import successWelcome from './illustrations/success-welcome.png';
import warning from './illustrations/warning.png';
import logo from './logos/logo.svg';
import logoDark from './logos/logo_dark.svg';
import logoLight from './logos/logo_light.svg';
import defaultSliderLarge from './sliders/default-slider-large.jpg';
import defaultSliderSmall from './sliders/default-slider-small.jpg';

export const coreAssets = generateAssetsContextValue(CoreAssetsContext)({
  /* UI buttons */
  backToTheTopArrow,
  genericError,
  networkError,
  /* Logos */
  logo,
  logoDark,
  logoLight,
  logoSmall: logo,
  /* Banners */
  defaultSliderLarge,
  defaultSliderSmall,
  /* Illustrations */
  notFound,
  failure,
  noResults,
  questioning,
  repair,
  successCashier,
  successWelcome,
  warning,
  /* Mobile app */
  androidApkEn,
  appStoreComingSoonEn,
  appStoreEn,
  googlePlayComingSoonEn,
  googlePlayEn,
  /* Geolocation */
  laptop,
  mobile,
});
