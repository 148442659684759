import { FC, Suspense } from 'react';

import { useBettingRoutePath } from '@gaming1/g1-betting-web';
import { useCmsRoutePath } from '@gaming1/g1-cms-web';
import { useGamingRoutePath } from '@gaming1/g1-gaming-web';
import { AccountLinks, MenuGroups, TopNav } from '@gaming1/g1-layout/web';
import { useLoyaltyRoutePath } from '@gaming1/g1-loyalty-web';
import { usePaymentPublicComponents } from '@gaming1/g1-payment-api';
import { AppLink } from '@gaming1/g1-ui';

import responsibleGaming from '../../assets/custom/header/responsible-gaming.png';
import { ResponsibleGamingImage } from '../OverwriteStyle';

export const AppTopNav: FC = () => {
  const getBettingPath = useBettingRoutePath();
  const getCmsPath = useCmsRoutePath();
  const getGamingPath = useGamingRoutePath();
  const getLoyaltyPath = useLoyaltyRoutePath();
  const { DepositButton } = usePaymentPublicComponents();

  const topNavMenuItems: MenuGroups = [
    [
      {
        label: 'menu.games',
        path: getGamingPath('gameList'),
        testId: 'topnav-games',
        icon: 'Slot',
      },
      {
        label: 'menu.sport',
        path: getBettingPath('betting'),
        testId: 'topnav-betting',
        icon: 'AmericanFootball',
      },
      {
        label: 'menu.promotions',
        path: getCmsPath('allPromotions'),
        testId: 'topnav-promotions',
        icon: 'BettingPromotion',
      },
      {
        label: 'menu.shop',
        path: getLoyaltyPath('shop'),
        testId: 'topnav-shop',
        icon: 'Shop',
      },
    ],
  ];

  return (
    <TopNav>
      <TopNav.Group>
        <TopNav.AppLogo />
        <AppLink
          to={getCmsPath('nav', {
            navUid: 'about-us',
            contentUid: 'responsible-gaming',
          })}
        >
          <ResponsibleGamingImage
            alt="header-responsible"
            id="header-responsible"
            url={responsibleGaming}
            height={36}
          />
        </AppLink>
        <TopNav.Menu menu={topNavMenuItems} />
      </TopNav.Group>
      <TopNav.Group>
        <Suspense fallback={null}>
          <DepositButton />
          <AccountLinks />
        </Suspense>
        <TopNav.SearchIcon />
      </TopNav.Group>
    </TopNav>
  );
};
