import { generateAssetsContextValue } from '@gaming1/g1-assets-management/web';
import { GameCatalogAssetsContext } from '@gaming1/g1-game-catalog/web';

import jackpotGameTag from './gamecard/jackpot.svg';
import newGameTag from './gamecard/new.svg';
import topGameTag from './gamecard/top.svg';

export const gameCatalogAssets = generateAssetsContextValue(
  GameCatalogAssetsContext,
)({
  /* Game tags */
  jackpotGameTag,
  newGameTag,
  topGameTag,
});
