import React, { FC, useContext } from 'react';

import { LastConnection, MenuGroups, TopBar } from '@gaming1/g1-layout/web';
import { ZendeskWidgetContext } from '@gaming1/g1-support-widget/web';
import { RenderOnMedia } from '@gaming1/g1-ui';
import { useUserPublicComponents } from '@gaming1/g1-user-api';

export const AppTopBar: FC = () => {
  const { showWidget } = useContext(ZendeskWidgetContext);
  const { SessionTime } = useUserPublicComponents();

  const topBarMenuItems: MenuGroups = [
    [
      {
        label: 'menu.help',
        onClick: showWidget,
        testId: 'topbar-help',
        icon: 'CircleQuestion',
      },
    ],
  ];

  return (
    <RenderOnMedia min="lg">
      <TopBar>
        <TopBar.Clock />
        <LastConnection />
        <SessionTime />
        <TopBar.Separator />
        <TopBar.Menu menu={topBarMenuItems} />
      </TopBar>
    </RenderOnMedia>
  );
};
