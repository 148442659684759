import { BehaviorSubject } from 'rxjs';

import {
  AppConfig,
  ConfigOverwriteByEnv,
  EGameSort,
  getConfig,
  PartnerConfig,
  Rooms,
} from '@gaming1/g1-config';

const defaultCsp = getConfig().security.csp;

export const baseConfig: PartnerConfig = {
  betting: {
    boostus: {
      enabled: true,
    },
    dailyOffers: {
      enabled: true,
    },
    defaultOddsUnit: 'american',
    enabled: true,
    favorites: {
      enabled: true,
    },
    lmt: {
      config: {
        disablePitchStripes: true,
        layout: 'single',
        pitchCustomBgColor: '#293342',
        tabsPosition: 'bottom',
      },
      enabled: true,
      fakeId: 0,
      theme: 'betly',
      url: 'de4f75930e98f328c9e763240489caf5',
    },
    minimalAge: 21,
    promotions: { enabled: true },
    showEmailNotifications: true,
    superOdds: {
      enabled: true,
    },
    tournamentor: {
      enabled: true,
    },
  },
  credit: {
    bonusPlaythroughCasinoCategories: [
      'blackjack',
      'liveGames',
      'slotAndDiceGames',
    ],
    shouldDisplayBonusPlaythrough: true,
  },
  core: {
    associatedDomains: [
      'betlywv-us-test01.gaming1.com',
      'betlywv-us-acc01.gaming1.com',
      'wv.betly.com',
    ],
    mobileAppIdentifiers: {
      android: 'com.gamewise.us.betly.wv.acceptance',
      ios: '1670211072',
    },
    privacyPoliciesContentUid: 'privacy-policy',
    termsAndConditionsContentUid: 'terms-of-service',
    zendeskWidgetKey: '85e68812-514e-4345-b8d1-0174c5843707',
  },
  gaming: {
    minimalAge: 21,
    defaultGameListOrder: EGameSort.None,
    isFreeroundsEnabled: true,
    arePlayerGameFeaturesEnabled: true,
    isJackpotValueOnThumbnailEnabled: true,
  },
  i18n: {
    availableLanguages: ['en'],
    dateFormat: 'MM/dd/yyyy',
    dateShortFormat: 'MM/dd',
    dateTimeFormat: 'MM/dd/yyyy hh:mm:ss aa',
    dateTimePartialFormat: 'MM/dd/yyyy hh:mm aa',
    dateTimeShortPartialFormat: 'MM/dd hh:mm aa',
    defaultLanguage: 'en',
    timeFormat: 'hh:mm:ss aa',
    timePartialFormat: 'hh:mm aa',
  },
  loyalty: {
    fullShop: true,
    isRedeemBonusCodeFormEnabled: true,
    loggedOutCmsHomePage: true,
    miniGamesServerUrls: {
      giftbox: 'https://dev-giftboxes-minigame.gaming1.com',
      wheel: 'https://dev-wheels-minigame.gaming1.com',
    },
    profileRank: false,
    shop: {
      header: {
        shouldDisplayShopIcon: true,
        shouldDisplaySubtitle: true,
      },
      shouldDisplaySearchBarAndSortFilter: true,
    },
    shouldUseLegacyUnitedStatesBehavior: true,
  },
  marketing: {
    promotions: {
      enabled: true,
    },
    sliderGroupBlacklist: ['tournaments-slider', 'best-games'],
  },
  network: {
    apiUrl: 'https://apifront-betlywv-us-front06.gaming1.com',
    // US sessions are really short so the keepalive requests should be frequent
    keepAliveInterval: 1 * 30 * 1000,
    mediaUrl: 'https://media-betlywv-us-front06.gaming1.com',
    prismicUrl: 'https://betlywv.cdn.prismic.io/api/v2',
    siteUrl: 'https://betlywv-us-front06.gaming1.com',
    wsUrl: 'wss://wss-betlywv-us-front06.gaming1.com:10002',
    zendeskUrl: 'https://support.wv.betly.com',
  },
  room: {
    roomName: Rooms.BETLYWV,
  },
  security: {
    csp: {
      ...defaultCsp,
      'connect-src': [
        ...defaultCsp['connect-src'],
        'https://*.betly.com',
        'wss://*.betly.com',
      ],
      'frame-src': [
        ...defaultCsp['frame-src'],
        'https://cashier.wv.betly.com',
        'https://betlywv.prismic.io',
      ],
    },
  },
  seo: {
    dataDog: {
      applicationId: '9f55ed26-8e83-4d39-8a49-a4a6cbf5052f',
      clientToken: 'pub53c7f763cb840a5ff92d91273489c975',
      partnerTag: 'wv.betly.com',
      site: 'datadoghq.eu',
    },
  },
  user: {
    amountIconSide: 'left',
    bonus: false,
    closeAccount: true,
    clockFormat: "hh':'mm aa",
    defaultCountry: 'us',
    deleteMobileAppAccount: true,
    deleteMobileAppAccountDirtyFlow: true,
    displayPersonalDataAddressState: true,
    displayPersonalDataBirthCity: false,
    displayPersonalDataBirthCountry: false,
    displayPersonalDataGender: false,
    displayPersonalDataLandbaseId: true,
    displayPersonalDataPhoneNumber: ['mobile'],
    fullAddressOrder: ['Line1', 'Line2', 'City', 'ZipCode'],
    fullAddressSeparator: ', ',
    gamePause: true,
    gamePauseDurations: [
      { duration: 3, unit: 'day' },
      { duration: 7, unit: 'day' },
      { duration: 14, unit: 'day' },
      { duration: 1, unit: 'month' },
    ],
    gamePauseInput: 'radio',
    globalSelfExclusion: false,
    hasSettingsBonusPage: true,
    histories: {
      adjustment: true,
      bonus: true,
      deposit: true,
      freeSpins: true,
      games: true,
      shop: true,
    },
    inactivityLogoutModal: true,
    inactivityLogoutModalEvents: ['click', 'keydown', 'scroll', 'wheel'],
    inbox: true,
    inboxProjectKey: 'eca6478c584ad0b61a452041b66f8543',
    isBonusMoneyDeletable: true,
    isSecurityNotificationAtLoginEnabled: true,
    isLicenseSelectionEnabled: false,
    isLiveChatEnabled: true,
    legalModal: false,
    limits: {
      connectionTime: true,
      coolOffInMinute: 1440,
      deposit: true,
      lossBetting: true,
      wageBetting: true,
    },
    multiFactorAuthentication: 'authenticator',
    privacyConditionsModal: false,
    registrationSportInvolvedCheck: false,
    registrationSuccessCheckAutenticationStatusPolling: true,
    remainingTimeLimit: true,
    selfExclusion: true,
    selfExclusionDurations: [
      { duration: 1, unit: 'year' },
      { duration: 3, unit: 'year' },
      { duration: 5, unit: 'year' },
    ],
    selfExclusionInput: 'radio',
    shouldDisplayAllLegalCheckboxes: true,
    shouldDisplayForceResetPasswordModal: true,
    shouldDisplayRegistrationPrizeCode: true,
    shouldDisplayRegistrationTermsAndPrivacyCheck: true,
    showLastConnection: true,
    zendeskUserNameFieldId: 7027610286365,
  },
};

export const configOverwrite: ConfigOverwriteByEnv = {
  local: {
    core: {
      googleAPIKey: 'AIzaSyAYMrXwuyaJte-nARkCegNT8nfR5RRhSks',
    },
  },
  development: {
    analytics: { gtmId: 'GTM-MFP25RS' },
    core: {
      googleAPIKey: 'AIzaSyAYMrXwuyaJte-nARkCegNT8nfR5RRhSks',
    },
  },
  testing: {
    analytics: { gtmId: 'GTM-MFP25RS' },
    core: {
      googleAPIKey: 'AIzaSyCpmjqsM8Msln_EqLRZsNIgQIy4pPpcuLw',
      mobileAppIdentifiers: {
        android: 'com.gamewise.us.betly.wv.testing',
        ios: '6449187501',
      },
    },
    network: {
      apiUrl: 'https://apifront-betlywv-us-test01.gaming1.com',
      mediaUrl: 'https://media-betlywv-us-test01.gaming1.com',
      siteUrl: 'https://betlywv-us-test01.gaming1.com',
      wsUrl: [
        'wss://wss-betlywv-us-test01.gaming1.com:443',
        'wss://wss02-betlywv-us-test01.gaming1.com:443',
      ],
    },
  },
  acceptance: {
    analytics: {
      gtmId: 'GTM-MFP25RS',
    },
    core: {
      geoComply: {
        activated: true,
        installerKey: 'EEqJlTchl0',
      },
      googleAPIKey: 'AIzaSyD5JiTG0g30qzw7_rOKLflxcL2Rzm7OUDM',
    },
    marketing: {
      appsFlyer: {
        devKey: 'sFgSNBsRJEJ32U8Co5CBoF',
      },
    },
    network: {
      apiUrl: 'https://apifront-betlywv-us-acc01.gaming1.com',
      mediaUrl: 'https://media-betlywv-us-acc01.gaming1.com',
      prismicUrl: 'https://betlywv.cdn.prismic.io/api/v2',
      siteUrl: 'https://betlywv-us-acc01.gaming1.com',
      wsUrl: [
        'wss://wss-betlywv-us-acc01.gaming1.com:443',
        'wss://wss02-betlywv-us-acc01.gaming1.com:443',
      ],
      zendeskUrl: 'https://support.wv.betly.com',
    },
    seo: {
      fullStoryOrgKey: 'o-1GJA2Q-na1',
    },
  },
  production: {
    analytics: {
      gtmId: 'GTM-KBGX2F4',
    },
    assetsManagement: {
      isImageOptimizationWithCdnEnabled: true,
    },
    core: {
      geoComply: {
        activated: true,
        environment: 'production',
        installerKey: 'EEqJlTchl0',
        umsServerUrl: 'https://ums.geocomply.com/installer/url/',
      },
      googleAPIKey: 'AIzaSyATSlNOpmCwg0D8MVNElsEiXMJBWeLrxo0',
      mobileAppIdentifiers: {
        android: 'com.gamewise.us.betly.wv',
        ios: '1670211134',
      },
      zendeskWidgetKey: 'ae9cb680-b233-488d-8c44-0ff927c65ed3',
    },
    loyalty: {
      loggedOutCmsHomePage: false,
      miniGamesServerUrls: {
        giftbox: 'https://prd-giftboxes-minigame.gaming1.com',
        wheel: 'https://prd-wheels-minigame.gaming1.com',
      },
    },
    marketing: {
      appsFlyer: {
        devKey: 'sFgSNBsRJEJ32U8Co5CBoF',
      },
    },
    network: {
      apiUrl: 'https://apifront.wv.betly.com',
      mediaUrl: 'https://media.wv.betly.com',
      prismicUrl: 'https://betlywv.cdn.prismic.io/api/v2',
      siteUrl: 'https://wv.betly.com',
      wsUrl: ['wss://wss01.wv.betly.com:443', 'wss://wss02.wv.betly.com:443'],
      zendeskUrl: 'https://support.wv.betly.com',
    },
    seo: {
      dataDog: {
        applicationId: '295b5ba2-3618-458a-83dc-909126d23a8a',
        clientToken: 'pubf13b87c12fb650604a64c75173544ba7',
      },
      fullStoryOrgKey: 'o-1GJ9YD-na1',
    },
    user: {
      inboxProjectKey: 'e941205c6eb1feaeeb2c7d1604428fba',
      zendeskUserNameFieldId: 360021920337,
    },
  },
};

export const config: AppConfig = getConfig(baseConfig, configOverwrite);

export const config$ = new BehaviorSubject(config);
