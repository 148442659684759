import { combineEpics } from 'redux-observable';

import { registerAccountAuthenticationStore } from '@gaming1/g1-account-authentication/shared';
import { registerAnalyticsStore } from '@gaming1/g1-analytics/shared';
import { registerBettingStore } from '@gaming1/g1-betting';
import { registerBettingFavoritesStore } from '@gaming1/g1-betting-favorites/shared';
import { registerBettingLiveStreamingStore } from '@gaming1/g1-betting-live-streaming/shared';
import { registerBettingPreferencesStore } from '@gaming1/g1-betting-preferences/shared';
import { registerBettingSuggestionsStore } from '@gaming1/g1-betting-suggestions/shared';
import { registerBettingTournamentorStore } from '@gaming1/g1-betting-tournamentor/shared';
import { registerCmsStore } from '@gaming1/g1-cms';
import {
  ApplicationState,
  CoreActions,
  coreEpics,
  coreReducer,
  filterCoreEpicsByEnv,
} from '@gaming1/g1-core';
import { getReduxMiddlewares } from '@gaming1/g1-core-web';
import {
  registerGameCatalogAPIStore,
  registerGameCatalogStore,
} from '@gaming1/g1-game-catalog/shared';
import { registerGameJackpotStore } from '@gaming1/g1-game-jackpot/shared';
import { registerGamingStore } from '@gaming1/g1-gaming';
import { registerLegalApprovalsStore } from '@gaming1/g1-legal-approvals/shared';
import { registerLimitsStore } from '@gaming1/g1-limits/shared';
import { getDeployEnv } from '@gaming1/g1-logger';
import { registerLoyaltyStore } from '@gaming1/g1-loyalty';
import { registerMultifactorAuthenticationStore } from '@gaming1/g1-multifactor-authentication/shared';
import { registerPaymentStore } from '@gaming1/g1-payment';
import { registerPersonalDetailsStore } from '@gaming1/g1-personal-details/shared';
import {
  registerPlayGameAPIStore,
  registerPlayGameStore,
} from '@gaming1/g1-play-game/shared';
import { registerPlayerAccountStore } from '@gaming1/g1-player-account/shared';
import { registerPlayerExclusionsStore } from '@gaming1/g1-player-exclusions/shared';
import { registerSliderStore } from '@gaming1/g1-slider/shared';
import { createStore } from '@gaming1/g1-store';
import { registerSupportWidgetStore } from '@gaming1/g1-support-widget/shared';
import { registerUserStore } from '@gaming1/g1-user';

import { config$ } from './config';

const epics = combineEpics<CoreActions, CoreActions, ApplicationState>(
  ...Object.values(filterCoreEpicsByEnv(coreEpics)),
);

const storeRegistrations = [
  /* Feature packages */
  registerBettingStore,
  registerCmsStore,
  registerGamingStore,
  registerLoyaltyStore,
  registerPaymentStore,
  registerUserStore,

  /* Autonomous packages */
  registerAccountAuthenticationStore,
  registerAnalyticsStore,
  registerBettingFavoritesStore,
  registerBettingLiveStreamingStore,
  registerBettingPreferencesStore,
  registerBettingSuggestionsStore,
  registerBettingTournamentorStore,
  registerGameCatalogStore,
  registerGameCatalogAPIStore,
  registerGameJackpotStore,
  registerLegalApprovalsStore,
  registerLimitsStore,
  registerMultifactorAuthenticationStore,
  registerPersonalDetailsStore,
  registerPlayGameAPIStore,
  registerPlayGameStore,
  registerPlayerAccountStore,
  registerPlayerExclusionsStore,
  registerSliderStore,
  registerSupportWidgetStore,
];

const reducers = {
  core: coreReducer,
};

export const createReduxStore = () => {
  const reduxStore = createStore({
    reducers,
    epics,
    config$,
    middlewares: [
      ...getReduxMiddlewares([
        'analytics/',
        'core/',
        'gaming/',
        'play_game/',
        'playGame_api/',
      ]),
    ],
  });

  storeRegistrations.forEach((registerToStore) => registerToStore(reduxStore));
  return reduxStore;
};

type AppReduxStore = ReturnType<typeof createReduxStore>;

type WindowWithReduxStore = Window & {
  store?: AppReduxStore;
};

const windowWithReduxStore = window as WindowWithReduxStore;

/**
 * Create the redux store and attach it to the global window object.
 * This allows the redux state to stay put between HMR.
 */
const configureStore = (): AppReduxStore => {
  if (windowWithReduxStore.store == null) {
    const reduxStore = createReduxStore();
    if (getDeployEnv() === 'local') {
      windowWithReduxStore.store = reduxStore;
    }
    return reduxStore;
  }
  return windowWithReduxStore.store;
};

export const store = configureStore();
