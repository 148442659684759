import React, { ComponentProps, FC, useContext } from 'react';

import {
  EBettingSlipIdentifier,
  useSelectedItemsList,
} from '@gaming1/g1-betting';
import { BettingMenuContext } from '@gaming1/g1-betting-ui';
import { useBettingRoutePath } from '@gaming1/g1-betting-web';
import { useCmsRoutePath } from '@gaming1/g1-cms-web';
import { useGamingRoutePath } from '@gaming1/g1-gaming-web';
import { BottomNav } from '@gaming1/g1-layout/web';
import { useLoyaltyRoutePath } from '@gaming1/g1-loyalty-web';
import { DrawerType, LayoutContext } from '@gaming1/g1-ui';

export const AppBottomNav: FC = () => {
  const getBettingPath = useBettingRoutePath();
  const getGamingPath = useGamingRoutePath();
  const getLoyaltyPath = useLoyaltyRoutePath();
  const getCmsPath = useCmsRoutePath();
  const { outcomeValue, liveCount, bsItemsCount } =
    useContext(BettingMenuContext);
  const { showDrawer } = useContext(LayoutContext);

  const betItems = useSelectedItemsList(EBettingSlipIdentifier.Main);
  const totalBetItems = betItems?.length || 0;

  const bottomNavMenuItems: ComponentProps<typeof BottomNav>['menus'] = {
    default: [
      {
        icon: 'Slot',
        label: 'menu.casino',
        path: getGamingPath('gameList'),
        testId: 'bottomnav-casino',
      },
      {
        icon: 'AmericanFootball' as const,
        label: 'menu.sport',
        path: getBettingPath('betting'),
        testId: 'bottomnav-betting',
      },
      {
        icon: 'BettingPromotion',
        label: 'menu.promotions',
        path: getCmsPath('allPromotions'),
        testId: 'bottomnav-promotions',
      },
      {
        icon: 'Shop' as const,
        label: 'menu.shop',
        path: getLoyaltyPath('shop'),
        testId: 'bottomnav-shop',
      },
    ],
    betting: [
      {
        icon: 'Live' as const,
        label: 'menu.live',
        path: getBettingPath('liveListTop'),
        testId: 'bottomnav-live',
        extraLabel: liveCount,
      },
      {
        highlighted: true,
        icon:
          totalBetItems > 0
            ? ('BettingSlipEmpty' as const)
            : ('BettingSlip' as const),
        label: outcomeValue,
        onClick: () => showDrawer(DrawerType.bettingSlip),
        testId: 'bottomnav-bettingslip',
        iconText: bsItemsCount,
      },
      {
        icon: 'MyBets' as const,
        label: 'menu.myBets',
        path: getBettingPath('myBets', { type: '' }),
        testId: 'bottomnav-mybets',
      },
      {
        icon: 'SportsAZUsa' as const,
        label: 'menu.allSports',
        onClick: () => showDrawer(DrawerType.allSports),
        testId: 'bottomnav-allsports',
      },
    ],
  };

  return <BottomNav animationType="hide" menus={bottomNavMenuItems} />;
};
