import React, { FC, useContext } from 'react';

import { OddsUnits, useBettingRoutePath } from '@gaming1/g1-betting-web';
import { useCmsRoutePath } from '@gaming1/g1-cms-web';
import { useConfig } from '@gaming1/g1-config';
import { useGamingRoutePath } from '@gaming1/g1-gaming-web';
import { DrawerNav, MenuGroups } from '@gaming1/g1-layout/web';
import { useLoyaltyRoutePath } from '@gaming1/g1-loyalty-web';
import { ZendeskWidgetContext } from '@gaming1/g1-support-widget/web';
import { Box } from '@gaming1/g1-ui';

import { FOOTER_LOGO_IMG_HEIGHT } from '../OverwriteStyle';

import { AppFooter } from './AppFooter';

export const AppDrawerNav: FC = () => {
  const config = useConfig();
  const getBettingPath = useBettingRoutePath();
  const getCmsPath = useCmsRoutePath();
  const getGamingPath = useGamingRoutePath();
  const getLoyaltyPath = useLoyaltyRoutePath();
  const { showWidget } = useContext(ZendeskWidgetContext);

  const mobileMenuItemsGroupOne: MenuGroups = [
    [
      {
        exact: true,
        label: 'menu.selection',
        path: getGamingPath('gameList'),
        testId: 'drawernav--selection',
        icon: 'Home',
      },
      {
        label: 'menu.mygames',
        path: getGamingPath('gameListMyGames'),
        testId: 'drawernav-mygames',
        icon: 'Heart',
      },
      {
        label: 'menu.slots',
        path: getGamingPath('gameListCategory', { category: 'slots-top' }),
        testId: 'drawernav-slots',
        icon: 'Flame',
      },
      {
        label: 'menu.table',
        path: getGamingPath('gameListCategory', { category: 'table-top' }),
        testId: 'drawernav-table',
        icon: 'Chips',
      },
    ],
  ];

  const mobileMenuItemsGroupTwo: MenuGroups = [
    [
      {
        label: 'menu.sport',
        path: getBettingPath('betting'),
        testId: 'drawernav-betting',
        icon: 'AmericanFootball',
      },
      {
        label: 'menu.bettingTournaments',
        path: getBettingPath('tournamentor', { type: '' }),
        testId: 'drawernav-tournaments',
        icon: 'Cup',
        hidden: !config.betting.tournamentor.enabled,
      },
    ],
  ];

  const mobileMenuItemsGroupThree: MenuGroups = [
    [
      {
        label: 'menu.promotions',
        path: getCmsPath('allPromotions'),
        testId: 'drawernav-promotions',
        icon: 'BettingPromotion',
      },
      {
        label: 'menu.shop',
        path: getLoyaltyPath('shop'),
        testId: 'drawernav-shop',
        icon: 'Shop',
      },
    ],
    [
      {
        label: 'menu.help',
        onClick: showWidget,
        testId: 'topbar-help',
        icon: 'CircleQuestion',
      },
    ],
  ];

  return (
    <DrawerNav>
      <DrawerNav.ContentContainer footerLogoHeight={FOOTER_LOGO_IMG_HEIGHT}>
        <DrawerNav.Menu menuLabel="menu.games" menu={mobileMenuItemsGroupOne} />
        <DrawerNav.Menu
          menuLabel="menu.betting"
          menu={mobileMenuItemsGroupTwo}
        />
        <DrawerNav.Menu
          menuLabel="menu.foryou"
          menu={mobileMenuItemsGroupThree}
        />
        <Box mt="auto" mb="md">
          <OddsUnits type="radio" />
        </Box>
      </DrawerNav.ContentContainer>
      <AppFooter />
    </DrawerNav>
  );
};
